export default (text:any) => {
  const languageSettings = {
    id: 1,
    topLabel: text.t('countrySelector.dropdownLanguage'),
    items: [
      {
        id: 0,
        name: text.t('countrySelector.nl.language'),
        short: 'nl_NL',
        forCountries: ['nl']
      },
      {
        id: 1,
        name: text.t('countrySelector.uk.language'),
        short: 'en_EN',
        forCountries: ['nl', 'uk', 'fr']
      },
      {
        id: 2,
        name: text.t('countrySelector.fr.language'),
        short: 'fr_FR',
        forCountries: ['fr']
      }
    ],
  }

  return {
    languageSettings
  }
}
