<script setup lang="ts">
import { Toaster } from 'temper-component-library'
import ClientNavbar from '../components/layout/ClientNavbar.vue'
import FreeflexerNavbar from '../components/layout/FreeflexerNavbar.vue'
import CountrySelector from '../components/layout/CountrySelector.vue'
import { useUserStore } from '../store/user'
import { useErrorHandlerStore } from '../store/errorHandler'

const userStore = useUserStore()
const errorStore = useErrorHandlerStore()
</script>

<template>
  <component :is="userStore.isWorker ? FreeflexerNavbar : ClientNavbar" />
  <slot />
  <CountrySelector />
  <Toaster
    v-if="errorStore.errorDisplay"
    :type="errorStore.errorType"
    :title="errorStore.errorTitle"
    :toaster-text="errorStore.errorMessage"
    :open="errorStore.errorDisplay"
    @close-toaster="errorStore.closeError(true)"
  />
</template>
