/* eslint-disable no-prototype-builtins */
export default (root:any, permissions:any, be:string, onboarded:boolean, badge:any) => {
  const clientTopNavigation = [
    {
      id: 1,
      permission: !onboarded,
      link: '/get-started/',
      linktext: root.t('header.clients.onboardingDashboard'),
    },
    {
      id: 2,
      permission: onboarded,
      link: '/dashboard/',
      linktext: root.t('header.clients.dashboard'),
    },
    {
      id: 3,
      link: be + '/dashboard/planning',
      linktext: root.t('header.clients.planning'),
      permission: true
    },

    {
      id: 4,
      link: be + '/client/projects',
      linktext: root.t('header.clients.projects'),
      permission: permissions && permissions.hasOwnProperty('projects.index')
    },
    {
      id: 5,
      link: be + '/dashboard/checkouts',
      linktext: root.t('header.clients.checkout'),
      permission: permissions && permissions.hasOwnProperty('matches.checkouts.manage'),
      badge
    },
    {
      id: 6,
      link: be + '/dashboard/client/flexpools',
      linktext: root.t('header.clients.flexpools'),
      permission: permissions && permissions.hasOwnProperty('flexpools.index'),
    },
  ]
  return {
    clientTopNavigation,
  }
}
