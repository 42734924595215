export default (text:any) => {
  const countrySettings = {
    id: 0,
    topLabel: text.t('countrySelector.dropdownCountry'),
    items: [
      {
        id: 0,
        name: text.t('countrySelector.nl.country'),
        flag: 'nl',
        country: 'nl'
      },
      {
        id: 1,
        name: text.t('countrySelector.uk.country'),
        flag: 'uk',
        country: 'uk'
      },
      {
        id: 2,
        name: text.t('countrySelector.fr.country'),
        flag: 'fr',
        country: 'fr'
      }
    ]
  }

  return {
    countrySettings,
  }
}
