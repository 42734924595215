export default (root:any, be:string) => {
  const freeflexerTopNavigation = [
    {
      id: 1,
      type: 'external',
      link: be + '/dashboard-contractor/',
      target: '_self',
      linktext: root.t('header.worker.dashboard'),
      permission: true
    },
    {
      id: 2,
      type: 'external',
      link: be + '/werk-zoeken/',
      target: '_self',
      linktext: root.t('header.worker.find_work'),
      permission: true
    },
  ]
  return {
    freeflexerTopNavigation
  }
}
