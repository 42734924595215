export default (root: any, be: string, country: string) => {
  const freeflexerDropdown = [
    {
      id: 1,
      type: 'title',
      content: root.t('header.worker.accountSettings'),
      variant: 'caption',
      class: 'grey600 caption',
    },
    {
      id: 2,
      type: 'external',
      link: be + '/dashboard/profile',
      target: '_self',
      linktext: root.t('header.worker.profile'),
      icon: 'profile',
      class: 'loggedin',
      permission: true
    },
    {
      id: 3,
      type: 'external',
      link: be + '/dashboard/flexpools',
      target: '_self',
      linktext: root.t('header.worker.flexpools'),
      icon: 'flexpool',
      class: 'user-link',
      permission: true
    },

    // it should be internal only for the FR
    {
      id: 4,
      type: country === 'fr' ? 'internal' : 'external',
      link: country === 'fr' ? '/dashboard/contractor/invoices' : be + '/dashboard/contractor/invoices',
      target: '_self',
      linktext: root.t('header.worker.finance'),
      icon: 'financeReceipt',
      class: 'user-link',
      permission: true
    },
    {
      id: 5,
      type: 'internal',
      link: '/freeflexer/insurances',
      target: '_self',
      linktext: root.t('header.worker.insurance'),
      icon: 'insurance',
      class: 'loggedin',
      permission: true
    },
    {
      id: 6,
      type: 'external',
      link: be + '/dashboard/notifications-settings',
      target: '_self',
      linktext: root.t('header.worker.settings'),
      icon: 'notificationOn',
      class: 'loggedin',
      permission: true
    },
    {
      id: 7,
      type: 'title',
      content: root.t('header.worker.moreFromTemper'),
      variant: 'caption',
      class: 'grey600 caption',
    },
    {
      id: 8,
      type: 'external',
      link: be + '/feedback/product-feedback',
      target: '_self',
      linktext: root.t('header.worker.feedback'),
      icon: 'speechBubble',
      class: 'loggedin',
      permission: true
    },
    {
      id: 9,
      type: 'external',
      link: root.t('helpSection.link'),
      target: '_blank',
      linktext: root.t('header.worker.help'),
      icon: 'bubbleQuestion',
      class: 'loggedin',
      permission: true
    },
    {
      id: 10,
      type: 'external',
      link: 'https://go.temper.works/app/temper-community',
      target: '_blank',
      linktext: root.t('header.worker.temperCommunity'),
      icon: 'flexpool',
      class: 'loggedin',
      permission: true
    },
    {
      id: 11,
      type: 'external',
      link: be + '/dashboard/referrals',
      target: '_self',
      linktext: root.t('header.worker.invite_friends'),
      icon: 'gift',
      class: 'user-link',
      permission: true
    },
  ]
  return {
    freeflexerDropdown
  }
}
