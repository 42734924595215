import { useCountryStore } from '../store/country'
export default (root:any) => {
  const countryStore = useCountryStore()
  const unauthenticatedMenu = [
    {
      id: 1,
      link: root.t(`websiteLinks.home.${countryStore.country}`),
      linktext: root.t('header.freeflexers'),
      permission: true
    },
    {
      id: 2,
      link: root.t(`websiteLinks.companies.${countryStore.country}`),
      linktext: root.t('header.companies'),
      permission: true
    },
    {
      id: 3,
      link: root.t(`websiteLinks.story.${countryStore.country}`),
      linktext: root.t('header.story'),
      permission: true
    },
  ]
  return {
    unauthenticatedMenu,
  }
}
