<script setup lang="ts">
// eslint-disable-next-line import/no-extraneous-dependencies
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import {
  HeaderContainer,
  StickyNavbar,
} from 'temper-component-library'
import { useCountryStore } from '../../store/country'
import { useUserStore } from '../../store/user'
import { useSettingsStore } from '../../store/settings'
import { useAuthStore } from '../../store/auth'
import { useFreeflexerProfileStore } from '../../store/freeflexer/profile'
import unauthenticatedMenu from '../../composables/freeflexer/navigationUnAuth'
import freeflexerDropdown from '../../composables/freeflexer/freeflexerDropdown'
import freeflexerTopNavigation from '../../composables/freeflexer/navigationAuth'
import freeflexerStickyNavbar from '../../composables/freeflexer/freeflexerStickyNavbar'

const text = useI18n()
const countryStore = useCountryStore()
const userStore = useUserStore()
const settingsStore = useSettingsStore()
const authStore = useAuthStore()
const nuxtApp = useNuxtApp()
const freeflexer = useFreeflexerProfileStore()

authStore.getToken()
userStore.getUser()
freeflexer.getOnboardingProgress()

const menuItems = ref<string[]>([])

function openCountrySelector() {
  countryStore.setCountryChange()
}

function logout() {
  nuxtApp.$router.push('/logout')
}
</script>
<template>
  <HeaderContainer
    :authenticated-user="userStore.loggedInUser ? true : false"
    :menu-items="
      userStore.loggedInUser ?
        freeflexerTopNavigation(text, countryStore.backend).freeflexerTopNavigation :
        unauthenticatedMenu(text).unauthenticatedMenu
    "
    :menu-items-dropdown="
      userStore.loggedInUser ?
        freeflexerDropdown(text, countryStore.backend, countryStore.country).freeflexerDropdown :
        menuItems
    "
    :mobile-device="settingsStore.isTablet"
    :country="countryStore.country"
    :user="userStore.loggedInUser ? userStore.user : {}"
    :show-complete-profile="true"
    :complete-profile-percentage="freeflexer.completeProfilePercentage"
    @country="openCountrySelector()"
    @logout="logout()"
  />
  <StickyNavbar
    v-if="userStore.loggedInUser && settingsStore.isMobile"
    :items="
      freeflexerStickyNavbar(
        text,
        countryStore.backend,
        countryStore.countrySpecificRoutes).freeflexerStickyNavbar
    "
  />
</template>
